import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
import qs from 'qs'
import { getToken } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000, // request timeout
  maxContentLength: Infinity,
  maxBodyLength: Infinity
})

service.interceptors.request.use(
  (config) => {
    if (config.data) {
      // post
      config.headers['Content-type'] = 'application/x-www-form-urlencoded'
      config.data = qs.stringify(config.data)
    }

    // console.log(config)

    if (config.url == '/script/api/exec') {
      // domain和sessionid调试接口不能加，因为用户用这个接口时，没有两个参数
      delete config.params.domain
      delete config.params.sessionid
      // /script/api/exec 接口为接口脚本配置，调用vcrm
      config.baseURL = process.env.VUE_APP_BASE_API.replace('erp', 'vcrm')
    }

    if (config.url.indexOf('/gears') != -1) {
      config.baseURL = process.env.VUE_APP_BASE_API.replace('/erp/', '')
      // config.baseURL = 'http://myy.xunkuyun.com/vcrm'
    }

    if (config.url.indexOf('open.ys7.com') != -1) {
      // domain和sessionid调试接口不能加，因为用户用这个接口时，没有两个参数
      delete config.params.domain
      delete config.params.sessionid
      delete config.headers['X-Token']
      config.withCredentials = false
    }

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // eslint-disable-line
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    let res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (
      res.status !== '00000' &&
      !response.config.url.includes('open.ys7.com')
    ) {
      Message({
        message: res.info || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      // Promise.reject(new Error(res.message || "Error"));
      return res
    } else {
      if (window.location.host == '123.156.65.70:88') {
        res = JSON.stringify(res).replaceAll(
          'cdnupd.yiwuplat.com',
          '123.156.65.70:88'
        )
        res = JSON.parse(res)
      }
      return res
    }
  },
  // (error) => {
  //   // console.log("err" + error); // for debug
  //   Message({
  //     message: error.message,
  //     type: 'error',
  //     duration: 5 * 1000
  //   })
  //   // return Promise.reject(error);
  //   return false
  // }
  (error) => {
    console.log('err', error, JSON.stringify(error)) // eslint-disable-line
    let errorMessage = error.message

    if (error?.name == 'CanceledError') {
      return false // 手动取消的不报错
    } else if (error.code == 'ERR_NETWORK') {
      errorMessage = '网络错误' // 错误提示变成中文
    } else if (error.code == 'ERR_BAD_RESPONSE') {
      errorMessage = '服务器响应慢，请稍后再试' // 502 504
    }

    if (error.code == 'ERR_NETWORK' || error.message == 'Network Error') {
      let config = error.config
      if (config.firstRetry) {
        // 返回重试请求
        Message({
          message: errorMessage,
          type: 'error',
          duration: 5 * 1000,
          showClose: true
        })
        // return false
        return Promise.reject(error)
      } else {
        // 网络错误重试一次
        const backoff = new Promise((resolve) => {
          setTimeout(() => {
            resolve()
          }, 1000 * 1)
        })

        // 返回重试请求
        config.firstRetry = true
        return backoff.then(() => {
          return service(config)
        })
      }
    } else {
      // 返回重试请求
      Message({
        message: errorMessage,
        type: 'error',
        duration: 5 * 1000,
        showClose: true
      })
      // return false
      return Promise.reject(error)
    }
  }
)

export default service
