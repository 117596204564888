import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
  {
    name: 'index',
    path: '/',
    component: () => import('@/view/region/index.vue')
  },
  {
    name: 'check',
    path: '/region/check',
    component: () => import('@/view/region/check.vue'),
    meta: {
      title: '二次登录'
    }
  },
  {
    name: 'statement',
    path: '/region/statement',
    component: () => import('@/view/region/statement.vue'),
    meta: {
      title: '产品公示'
    }
  },
  {
    name: 'supplier',
    path: '/region/supplier',
    component: () => import('@/view/region/supplier.vue'),
    meta: {
      title: '服务商中心'
    }
  },
  {
    name: 'contact',
    path: '/region/contact',
    component: () => import('@/view/region/contact.vue'),
    meta: {
      title: '支持服务'
    }
  },
  {
    name: 'report',
    path: '/region/report',
    component: () => import('@/view/region/report.vue'),
    meta: {
      title: '行业报告'
    }
  },
  {
    name: 'train',
    path: '/region/train',
    component: () => import('@/view/region/train.vue'),
    meta: {
      title: '培训中心'
    }
  },
  {
    name: 'trainDetail',
    path: '/region/trainDetail',
    component: () => import('@/view/region/trainDetail.vue'),
    meta: {
      title: '培训中心详情'
    }
  },
  {
    name: 'policy',
    path: '/region/policy',
    component: () => import('@/view/region/policy.vue'),
    meta: {
      title: '政策向导'
    }
  },
  {
    name: 'policyDetail',
    path: '/region/policyDetail',
    component: () => import('@/view/region/policyDetail.vue'),
    meta: {
      title: '政策向导详情'
    }
  },
  {
    name: 'case',
    path: '/region/case',
    component: () => import('@/view/region/case.vue'),
    meta: {
      title: '数改案例'
    }
  },
  {
    name: 'solution',
    path: '/region/solution',
    component: () => import('@/view/region/solution.vue'),
    meta: {
      title: '解决方案'
    }
  },
  {
    name: 'solutionDetail',
    path: '/region/solutionDetail',
    component: () => import('@/view/region/solutionDetail.vue'),
    meta: {
      title: '解决方案详情'
    }
  },
  {
    name: 'apps',
    path: '/region/apps',
    component: () => import('@/view/region/apps.vue'),
    meta: {
      title: '应用中心'
    }
  },
  {
    name: 'appsDetail',
    path: '/region/appsDetail',
    component: () => import('@/view/region/appsDetail.vue'),
    meta: {
      title: '应用中心详情'
    }
  },
  {
    name: 'coupon',
    path: '/region/coupon',
    component: () => import('@/view/region/coupon.vue'),
    meta: {
      title: '优惠券'
    }
  },
  {
    name: 'developer',
    path: '/region/developer',
    component: () => import('@/view/region/developer.vue'),
    meta: {
      title: '工程师'
    }
  },
  {
    name: 'developerDetail',
    path: '/region/developerDetail',
    component: () => import('@/view/region/developerDetail.vue'),
    meta: {
      title: '工程师详情'
    }
  },
  {
    name: 'diagnosis',
    path: '/region/diagnosis',
    component: () => import('@/view/region/diagnosis.vue'),
    meta: {
      title: '诊断中心'
    }
  },
  {
    name: 'industry',
    path: '/region/industry',
    component: () => import('@/view/region/industry.vue'),
    meta: {
      title: '工业'
    }
  },
  {
    name: 'industryDetail',
    path: '/region/industryDetail',
    component: () => import('@/view/region/industryDetail.vue'),
    meta: {
      title: '工业详情'
    }
  },
  {
    name: 'industryDetailMore',
    path: '/region/industryDetailMore',
    component: () => import('@/view/region/industryDetailMore.vue'),
    meta: {
      title: '工业详情'
    }
  },
  {
    name: 'login',
    path: '/region/login',
    component: () => import('@/view/region/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'register',
    path: '/region/register',
    component: () => import('@/view/region/register.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    name: 'order',
    path: '/region/order',
    component: () => import('@/view/region/order.vue'),
    meta: {
      title: '订单'
    }
  },
  {
    name: 'partner',
    path: '/region/partner',
    component: () => import('@/view/region/partner.vue'),
    meta: {
      title: '合作伙伴'
    }
  },
  {
    name: 'sustainable',
    path: '/region/sustainable',
    component: () => import('@/view/region/sustainable.vue'),
    meta: {
      title: '可持续发展'
    }
  },
  {
    name: 'service',
    path: '/region/service',
    component: () => import('@/view/region/service.vue'),
    meta: {
      title: '找服务'
    }
  },
  {
    name: 'serviceDetail',
    path: '/region/serviceDetail',
    component: () => import('@/view/region/serviceDetail.vue'),
    meta: {
      title: '服务详情'
    }
  },
  // {
  //   name: 'HiPrint',
  //   path: '/print/index',
  //   component: () => import('@/view/print/index.vue'),
  //   meta: {
  //     title: 'list15'
  //   }
  // },
  {
    name: 'Yozo',
    path: '/yozo/index',
    component: () => import('@/view/yozo/index.vue'),
    meta: {
      title: 'list16'
    }
  },
  {
    name: 'YozoEdit',
    path: '/yozo/edit',
    component: () => import('@/view/yozo/edit.vue'),
    meta: {
      title: 'list17'
    }
  },
  {
    path: '*',
    redirect: '/',
    name: 'not-match'
  }
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
