import { getExec, postExec } from '@/api/scriptInterface'
import md5 from 'md5'
// import store from '@/store'

const that = {
  requestType: 'get',
  apiInfo: {
    md5Password: 'a7f31cfe-b3bb-11eb-bf56-00163e15d26d'
  },
  apiParmJson: {},
  parameters: [],
  responseData: '',
  ruleForm: {
    apikey: '',
    companyid: '5010686',
    timestamp: '',
    userid: 'myy@xunkukeji.com'
  },
  apisign: '',
  apisignStr_: ''
}

const apisignStr = () => {
  const { apiParmJson, ruleForm, apiInfo } = that
  const fixedEncodeURIComponent = function (str) {
    return encodeURIComponent(str).replace(/[!'()~]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16).toUpperCase()
    })
  }
  // let customParam = {}
  let total = ''
  // if (apiParmJson.in) {
  //   apiParmJson.in.forEach((v) => {
  //     customParam[v.parm] = v.sample
  //   })
  // }
  const sortParam = { ...ruleForm, ...apiParmJson }
  const keys = Object.keys(sortParam).sort()
  for (let prop of keys) {
    let value = fixedEncodeURIComponent(sortParam[prop])
    total += `${[prop]}=${value}&`
  }
  total = total.substr(0, total.length - 1)
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  that.apisignStr_ = total
  total = `${total}${apiInfo.md5Password}`
  return total
}

export function newRequest(qes) {
  that.requestType = qes.requestType
  that.ruleForm.apikey = qes.apikey
  that.apiParmJson = qes.data

  that.ruleForm.timestamp = new Date().getTime().toString().substr(0, 13)
  that.apisign = md5(apisignStr())
  that.parameters = []
  let arr = that.apisignStr_.split('&')
  arr.forEach((v) => {
    let prop = v.split('=')
    that.parameters.push({ name: prop[0], value: prop[1] })
  })
  that.parameters.push({ name: 'apisign', value: that.apisign })

  const { requestType, ruleForm, apiParmJson, apisign } = that
  // let obj = {}
  // if (apiParmJson.in) {
  //   apiParmJson.in.forEach((v) => {
  //     obj[v.parm] = v.sample
  //   })
  // }
  let param = { ...ruleForm, ...apiParmJson, apisign: apisign }
  if (requestType === 'get') {
    getExec({ ...param }).then((res) => {
      if (res) {
        if (window.location.href.indexOf('https://yiwuplat.yw.gov.cn/') != -1) {
          res.data = JSON.parse(
            JSON.stringify(res.data)
              .replaceAll(
                'http://cdnupd.yiwuplat.com/img_upload/',
                'https://yiwuplat.yw.gov.cn/img_upload/yiwuplat/'
              )
              .replaceAll(
                'https://cdnupd.yiwuplat.com/img_upload/',
                'https://yiwuplat.yw.gov.cn/img_upload/yiwuplat/'
              )
          )
        }
        qes.success(res)
      }
    })
  } else {
    postExec({ ...param }).then((res) => {
      if (res) {
        if (window.location.href.indexOf('https://yiwuplat.yw.gov.cn/') != -1) {
          res.data = JSON.parse(
            JSON.stringify(res.data)
              .replaceAll(
                'http://cdnupd.yiwuplat.com/img_upload/',
                'https://yiwuplat.yw.gov.cn/img_upload/yiwuplat/'
              )
              .replaceAll(
                'https://cdnupd.yiwuplat.com/img_upload/',
                'https://yiwuplat.yw.gov.cn/img_upload/yiwuplat/'
              )
          )
        }
        qes.success(res)
      }
    })
  }
}

// export default newRequest
