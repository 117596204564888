import Vue from 'vue'
import router from '@/router/index.js'
import '@/styles/index.scss' // global css
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App'

Vue.use(ElementUI)

new Vue({
  router,
  el: '#app',
  render: (h) => h(App)
})
