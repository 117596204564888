<template>
  <div class="appcontainer">
    <div class="top-wrap">
      <div class="top-nav">
        <div class="left">
          <div class="left-menu" @mouseleave="extraLeave">
            <!-- <i class="el-icon-s-unfold"></i> -->
            <img src="@/assets/list.png" alt />
            <div class="left-list">
              <div
                :class="{'arrow' : item.list.length}"
                v-for="(item,index) in extraList"
                @mouseover="extraHover(index)"
                @click="pageTap(item.url)"
              >{{item.name}}</div>
            </div>
            <div class="right-list" :class="{'show' : extraList2.length}">
              <div v-for="item in extraList2">{{item.name}}</div>
            </div>
          </div>
          <div class="left-logo">
            <img src="@/assets/y-logo-white.png" alt />
          </div>
          <div class="left-desc">{{webInfo.webTitle}}</div>
        </div>
        <div class="right" v-if="!userInfo.mobile">
          <span class="right-pointer" @click="pageTap('login?to=scan')">企业注册</span>
          <span class="right-pointer" @click="pageTap('login')">登录</span>
          <!-- <span @click="pageTap('register')">注册</span> -->
        </div>
        <div class="right right2" v-else>
          <span>{{userInfo.ispName}}</span>
          <i class="el-icon-caret-bottom"></i>
          <div class="list">
            <!-- <div @click="enterGears">进入后台</div> -->
            <div>
              <a :href="platLink" target="_blank">进入后台</a>
            </div>
            <div @click="exit">退出登录</div>
          </div>
        </div>
        <div class="right-new" v-if="0">
          <div class="user">
            <img class="user-head" :src="userInfo.headImage || require('@/assets/avatar.png')" alt />
            <div class="user-box">
              <img :src="userInfo.headImage || require('@/assets/avatar.png')" alt />
              <div>{{userInfo.ispName}} {{userInfo.companyCreator}}</div>
              <div>{{userInfo.companyEmail}}</div>
              <div>
                <span>{{userInfo.mobile.substr(0,3)}}****{{userInfo.mobile.substr(7,4)}}</span>
                <span>|</span>
                <span @click="exit">退出</span>
              </div>
            </div>
          </div>
          <a :href="platLink" target="_blank">进入后台</a>
        </div>
      </div>
      <div class="sub-nav">
        <div class="sub-nav-left" :class="('sub-nav-' + navIndex)" @mouseleave="navLeave">
          <div
            :class="{'cur' : path == 'index'}"
            @click="pageTap('index',0)"
            @mouseover="navHover(0)"
          >
            <img v-if="(path == 'index' || navIndex == 0)" src="@/assets/y-logo-blue.png" alt />
            <img v-else src="@/assets/y-logo-white.png" alt />
            <span>主页</span>
          </div>
          <div
            v-for="(item,index) in navList"
            :key="'nav-'+index"
            :class="{'cur' : path.indexOf(item.path) != -1}"
            @click="pageTap(item.path,index+1)"
            @mouseover="navHover(index+1)"
            v-show="item.hide?false:true"
          >{{item.name}}</div>
        </div>
        <div class="sub-nav-left sub-nav-right">
          <div
            v-for="(item,index) in navList"
            :key="'nav-'+index"
            :class="{'cur' : path.indexOf(item.path) != -1}"
            @click="pageTap(item.path,index+1)"
            v-show="item.hide?true:false"
          >{{item.name}}</div>
        </div>
      </div>
    </div>

    <div class="bottom-wrap">
      <div class="bottom" v-if="0">
        <div class="left">
          <div class="top">
            <img src="@/assets/y-logo-white.png" alt />
            <div class="top-cont">
              <div>{{webInfo.webTitle}}</div>
              <div>"工业互联网平台 + 小快轻准产品" 服务</div>
            </div>
          </div>
          <div class="item">
            <i class="el-icon-location-outline"></i>
            <span>浙江省金华市义乌市江东路中路2号4号楼13楼</span>
          </div>
          <!-- <div class="item">
            <i class="el-icon-phone-outline"></i>
            <span>咨询热线：0571-87648207</span>
          </div>-->
        </div>
        <div class="list">
          <div class="title">友情链接</div>
          <div class="item">
            <a href="http://jxt.zj.gov.cn/" target="_blank">浙江省经济和信息化厅</a>
          </div>
          <div class="item">
            <a href="http://jxj.jinhua.gov.cn/" target="_blank">金华市经信局</a>
          </div>
          <div class="item">
            <a href="http://www.yw.gov.cn/" target="_blank">义乌市人民政府</a>
          </div>
          <div class="item">
            <a href="http://www.aii-alliance.org/" target="_blank">中国工业互联网联盟</a>
          </div>
        </div>
        <div class="list">
          <div class="title">支持与服务</div>
          <div class="item">关于我们</div>
          <div class="item">联系我们</div>
          <div class="item pointer" @click="maskUser = true">用户协议</div>
          <div class="item pointer" @click="maskPrivacy = true">隐私协议</div>
        </div>
        <div class="list none">
          <div class="title">快速入口</div>
          <div class="item pointer" @click="pageTap('solution')">解决方案</div>
          <div class="item pointer" @click="pageTap('apps')">应用组件</div>
          <div class="item pointer" @click="pageTap('industryDetail')">生态服务商</div>
          <div class="item pointer" @click="pageTap('developer')">专家中心</div>
        </div>
      </div>
      <div class="new-bottom">
        <img src="@/assets/newindex/newbottomlogo.png" alt />
      </div>
      <div class="copyright-gray">
        <span>主办单位：</span>
        <img src="@/assets/copyright1.png" alt />
        <a href="http://www.yw.gov.cn/col/col1229129435/index.html" target="_blank">义乌市经济与信息化局</a>
        <span>技术支持单位：</span>
        <img src="@/assets/copyright2.png" alt />
        <a href="https://www.aiit.org.cn/" target="_blank">北大信息技术高等研究院</a>
        <img src="@/assets/copyright5.png" alt />
        <a href="https://www.xunkukeji.com/" target="_blank">杭州讯酷科技有限公司</a>
      </div>
      <div class="copyright-blue">
        <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备11059913号-4</a>
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802008458"
          target="_blank"
        >浙公网安备33010802008458号</a>
      </div>
    </div>

    <div class="mask-agreement" v-if="maskUser" @mousewheel.prevent>
      <div class="white-box">
        <div class="title">{{webInfo.webTitle}}用户协议</div>
        <div class="content">
          <iframe style="width:100%;height:100%;" src="/UserAgreement.pdf" frameborder="0"></iframe>
        </div>
        <div class="btn">
          <el-button type="primary" @click="maskUser = false">关闭</el-button>
        </div>
      </div>
    </div>
    <div class="mask-agreement" v-if="maskPrivacy" @mousewheel.prevent>
      <div class="white-box">
        <div class="title">{{webInfo.webTitle}}隐私协议</div>
        <div class="content">
          <iframe style="width:100%;height:100%;" src="/PrivacyAgreement.pdf" frameborder="0"></iframe>
        </div>
        <div class="btn">
          <el-button type="primary" @click="maskPrivacy = false">关闭</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import store from '@/store'
import { mapGetters } from 'vuex'
import { newRequest } from '@/utils/beforeRequest'

export default {
  props: {
    topOrBottom: {
      type: String,
      require: true,
      default: 'top'
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  data() {
    return {
      webInfo: {
        webTitle: '讯酷科技'
      },
      maskUser: false,
      maskPrivacy: false,
      backgroundImage: require('@/assets/new-bg.png'),
      pageType: 'index',
      detailData: {},
      mastValue: {},
      userInfo: {},
      path: 'index', //仅用于锁定当前选中导航
      navIndex: -1, //仅用于导航下面的小横线
      navIndex_temp: 0,
      navList: [
        {
          path: 'solution',
          name: '解决方案',
          hide: false
        },
        {
          path: 'apps',
          name: '应用组件',
          hide: false
        },
        // {
        //   path: 'policy',
        //   name: '服务中心',
        //   hide: false
        // },
        {
          path: 'service',
          name: '找服务',
          hide: false
        },
        // {
        //   path: 'report',
        //   name: '行业报告',
        //   hide: false
        // },
        {
          path: 'case',
          name: '数改案例',
          hide: false
        },
        // {
        //   path: 'train',
        //   name: '培训中心',
        //   hide: false
        // },
        {
          path: 'sustainable',
          name: '可持续发展',
          hide: false
        }
        // {
        //   path: 'industryDetail',
        //   name: '生态服务商',
        //   hide: true
        // },
        // {
        //   path: 'developer',
        //   name: '专家中心',
        //   hide: true
        // }
        // {
        //   path: 'coupon',
        //   name: '领券中心',
        //   hide: true
        // }
      ],
      extraIndex: -1,
      extraList: [
        {
          name: '服务商中心',
          list: [],
          url: 'supplier'
        },
        {
          name: '平台运营中心',
          list: [],
          url: 'check?type=1',
          out: true
        },
        {
          name: '数改中心',
          list: [],
          url: 'check?type=2',
          out: true
        },
        {
          name: '支持服务',
          list: [],
          url: 'contact'
        }
      ],
      extraList2: [],
      suffix: '.xunkuyun.com',
      platLink: 'http://gears.xunkuyun.com/vcrm/'
    }
  },
  watch: {
    $route() {
      if (this.$route.path == '/') {
        this.path = 'index'
      } else {
        this.path = this.$route.path.split('/region/')[1]
      }
    }
  },
  created() {
    this.getWebInfo()
    // 获取用户信息
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') || '{}')
    // 获取当前域名后缀
    this.calcLink()
  },
  methods: {
    calcLink() {
      if (window.location.href.indexOf('.com') != -1) {
        // let arr = window.location.origin.split('.')
        // let suffix = '.' + arr[arr.length - 2] + '.' + arr[arr.length - 1]
        this.suffix = '.' + window.location.hostname.replace('www.', '')
        this.platLink = 'http://gears' + this.suffix + '/vcrm/'
      }
      // 义乌政府网站下-默认为-'.yiwuplat.com'
      if (window.location.href.indexOf('yiwuplat.yw.gov.cn') != -1) {
        this.suffix = '.yiwuplat.com'
        this.platLink = 'http://gears' + this.suffix + '/vcrm/'
      }
      // 修改后台链接为自己设置的domain
      this.fixLink()
    },
    fixLink() {
      if (this.userInfo.domain) {
        this.platLink = 'http://' + this.userInfo.domain + this.suffix + '/vcrm/'
      }
      // 服务商只有sessionid，走freelogin直接传sessionid
      if (this.userInfo.sessionid) {
        this.platLink = 'https://yiwuplat.yw.gov.cn/vcrm/#/freelogin?sessionid=' + this.userInfo.sessionid
      }
      // 在原有基础上新增如果存在sessionid直接跳入自动登录
      if (this.userInfo.domain && this.userInfo.sessionid) {
        this.platLink =
          'http://gears' + this.suffix + '/vcrm/#/register?area=1&domain=' + this.userInfo.domain + this.suffix + '&sessionid=' + this.userInfo.sessionid
      }
      // 0528-进入后台，直接切换到新的后台-只有企业才需要
      if (this.userInfo.belongToType == '企业') {
        let url = 'https://one.xunkuyun.com/#/login'
        if (this.userInfo.companyId && this.userInfo.loginmb) {
          url += `?companyId=${this.userInfo.companyId}&loginmb=${this.userInfo.loginmb}&sp=${this.userInfo.loginps}`
        }
        this.platLink = url
      }
    },
    enterGears() {
      if (this.userInfo.domain && this.userInfo.sessionid) {
        this.loginPost()
      } else {
        window.open(this.platLink)
      }
    },
    loginPost() {
      let temp = {
        mobile: this.userInfo.mobile,
        password: this.userInfo.password
      }
      newRequest({
        requestType: 'get',
        apikey: 'WEB.login',
        data: temp,
        success: (res) => {
          if (res.status == '00000') {
            if (res.data.ispId && res.data.domain && res.data.sessionid) {
              this.userInfo = res.data
              window.sessionStorage.setItem('userInfo', JSON.stringify(res.data))
              window.open('http://gears' + this.suffix + '/vcrm/#/register?area=1&domain=' + res.data.domain + this.suffix + '&sessionid=' + res.data.sessionid)
            } else {
              // this.$message.error('手机号或密码错误，获取用户信息失败')
            }
          } else {
            this.load = false
          }
        }
      })
    },
    extraLeave() {
      this.extraList2 = []
    },
    extraHover(index) {
      this.extraIndex = index
      this.extraList2 = this.extraList[index].list
    },
    exit() {
      this.userInfo = {}
      window.sessionStorage.setItem('userInfo', JSON.stringify({}))
      location.reload()
    },
    navHover(index) {
      this.navIndex = index
    },
    navLeave() {
      this.navIndex = -1
    },
    pageTap(item, index) {
      if (item.indexOf('check') != -1) {
        window.open(window.location.origin + '#/region/' + item)
      } else {
        let hashArr = window.location.hash.split('/')
        if (hashArr[hashArr.length - 1] != item && !(item == 'index' && window.location.hash == '#/')) {
          this.$router.push('/region/' + item)
          this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') || '{}')
          this.fixLink()
        }
      }
    },
    getWebInfo() {
      newRequest({
        requestType: 'get',
        apikey: 'WEB.getWebInfo',
        data: {
          areaLink: 'yiwu'
        },
        success: (res) => {
          if (res.status == '00000') {
            if (res.data.length) {
              this.webInfo = res.data[0]
              this.backgroundImage = res.data[0].background || this.backgroundImage
              window.sessionStorage.setItem('webInfo', JSON.stringify(this.webInfo))

              document.title = this.webInfo.webTitle
            }
          } else {
            this.load = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.appcontainer {
  // min-height: 100vh;
  position: relative;
  // background: linear-gradient(140deg, #4d61e9 0%, #48bcfc 100%);
  background-position: center;
  background-size: cover;
  background-color: #f7f9ff;
  &.f2 {
    background: #f2f2f2;
  }

  .mask-agreement {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.5);
    z-index: 100;
    .white-box {
      width: 800px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 5px;
      padding: 20px;
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        border-bottom: 1px solid #eee;
        padding: 0 0 15px;
      }
      .content {
        width: 100%;
        height: 500px;
        margin: 15px auto;
      }
      .btn {
        padding: 15px 0 0;
        border-top: 1px solid #eee;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .bottom-wrap {
    width: 100%;
    background: #212529;
    padding-top: 1px;
    .new-bottom {
      width: 420px;
      margin: 80px auto;
      img {
        display: block;
        width: 100%;
      }
    }
    .copyright-gray {
      width: 100%;
      height: 44px;
      background: #202020;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        height: 16px;
        margin-right: 8px;
      }
      span,
      a {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #adb5bd;
        line-height: 21px;
        margin-right: 12px;
      }
      a {
        margin-right: 20px;
        &:hover {
          color: #fff;
        }
      }
    }
    .copyright-blue {
      width: 100%;
      height: 44px;
      background: #121212;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #adb5bd;
        line-height: 21px;
        margin: 0 5px;
        &:hover {
          color: #fff;
        }
      }
    }
    .bottom {
      width: 1200px;
      padding: 50px 0 65px;
      margin: 0 auto;
      display: flex;
      .left {
        width: 600px;
        padding-top: 10px;
        .top {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          img {
            width: 50px;
          }
          div.top-cont {
            margin-left: 20px;
            div:nth-child(1) {
              font-size: 24px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #fff;
              line-height: 40px;
            }
            div:nth-child(2) {
              margin-top: 6px;
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #fff;
              line-height: 25px;
            }
          }
        }
        .center {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 400;
          color: #fff;
          line-height: 28px;
          margin: 20px 0;
        }
        .item {
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fff;
          line-height: 21px;
          margin: 0 0 14px;
          i {
            margin-right: 5px;
          }
        }
      }
      .list {
        margin-right: 85px;
        &.none {
          margin-right: 0;
        }
        .title {
          font-size: 20px;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 400;
          color: #fff;
          line-height: 28px;
          margin: 0 0 32px;
        }
        .item {
          font-size: 14px;
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          font-weight: 400;
          color: #adb5bd;
          line-height: 20px;
          margin: 0 0 12px;
          a {
            color: #adb5bd;
            &:hover {
              color: #fff;
            }
          }
          &:hover {
            color: #fff;
          }
          &.pointer {
            cursor: pointer;
          }
        }
      }
    }
  }
  .top-nav {
    width: 100%;
    height: 48px;
    background: #101821;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .left-menu {
        padding: 0 10px;
        border-right: 1px solid #5e6268;
        height: 48px;
        position: relative;
        img {
          width: 24px;
          padding-top: 12px;
        }
        &:hover {
          background: #008afc;
          .left-list {
            left: 0;
          }
        }
        .left-list,
        .right-list {
          border-top: 1px solid #5e6268;
          border-right: 1px solid #5e6268;
          position: absolute;
          left: -200px;
          top: 48px;
          width: 200px;
          height: calc(100vh - 48px);
          background: #101821;
          padding: 20px 20px;
          transition: all 0.5s;
          z-index: 9;
          div {
            font-size: 14px;
            color: #fff;
            line-height: 24px;
            padding: 10px 0;
            position: relative;
            cursor: pointer;
            &.arrow::after {
              content: '';
              width: 6px;
              height: 6px;
              border-top: 2px solid #fff;
              border-right: 2px solid #fff;
              position: absolute;
              right: 10px;
              top: calc(50% - 5px);
              transform: rotateZ(45deg);
            }
            &:hover {
              color: #008afc;
              &.arrow::after {
                border-color: #008afc;
              }
            }
          }
        }
        .right-list {
          background: #141d29;
          z-index: 8;
          &.show {
            left: 200px;
          }
        }
      }
      .left-logo {
        height: 44px;
        padding: 12px 15px;
        img {
          height: 100%;
        }
      }
      .left-desc {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 18px;
        padding: 0px 15px;
        position: relative;
        &::before {
          content: '';
          width: 2px;
          height: 18px;
          background: #fff;
          position: absolute;
          left: 0px;
          top: calc(50% - 8px);
        }
      }
    }
    .right {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 48px;
      // padding: 0 10px;
      margin-right: 40px;
      .right-pointer {
        cursor: pointer;
        display: inline-block;
        padding: 0 10px;
        &:hover {
          color: #008afc;
        }
      }
      span {
        cursor: pointer;
        // margin: 0 5px;
      }
      &.right2 {
        span {
          color: #008afc;
        }
        i {
          transition: all 0.5s;
        }
        &:hover {
          i {
            transform: rotateZ(-180deg);
          }
          .list {
            display: block;
          }
        }
      }
      .list {
        display: none;
        position: absolute;
        left: calc(50% - 60px);
        top: 48px;
        background: #1c2b3b;
        padding: 10px 0;
        width: 120px;
        div {
          color: #fff;
          line-height: 1;
          padding: 10px 0;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
          a {
            color: #fff;
          }
        }
      }
    }
    .right-new {
      padding: 0 10px;
      margin-right: 80px;
      display: flex;
      a {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 48px;
        text-decoration: none;
        margin: 0 15px;
      }
      .user {
        // width: 32px;
        height: 32px;
        margin-top: 8px;
        position: relative;
        img.user-head {
          display: block;
          height: 100%;
        }
        &:hover {
          .user-box {
            display: block;
          }
        }
        .user-box {
          display: none;
          position: absolute;
          left: 50%;
          top: 42px;
          transform: translateX(-50%);
          width: 340px;
          padding: 20px;
          background: #f3f8fe;
          border-radius: 10px;
          &::before {
            content: '';
            width: 100%;
            height: 20px;
            // background: #333;
            position: absolute;
            left: 0;
            top: -15px;
          }
          &::after {
            content: '';
            width: 20px;
            height: 20px;
            background: #f3f8fe;
            position: absolute;
            left: calc(50% - 10px);
            top: -3px;
            transform: rotateZ(45deg);
          }
          img {
            display: block;
            width: 60px;
            margin: 0 auto 15px;
          }
          div:nth-child(2) {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333;
            line-height: 22px;
            margin-bottom: 5px;
            text-align: center;
          }
          div:nth-child(3) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333;
            line-height: 22px;
            margin-bottom: 15px;
            text-align: center;
          }
          div:nth-child(4) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333;
            line-height: 22px;
            text-align: center;
            margin-bottom: 5px;
            span:nth-child(1) {
              color: #0098ec;
            }
            span:nth-child(2) {
              margin: 0 5px;
            }
            span:nth-child(3) {
              color: #e54643;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .sub-nav {
    width: 100%;
    height: 51px;
    background: #101821;
    border-top: 1px solid #5e6268;
    position: fixed;
    left: 0;
    top: 48px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 50px;
    .sub-nav-left {
      display: flex;
      align-items: center;
      height: 50px;
      padding-left: 50px;
      &::after {
        content: '';
        width: 0;
        height: 3px;
        background: #008afc;
        position: absolute;
        left: 39px;
        bottom: 0px;
        transition: all 0.5s;
      }
      &.sub-nav-0::after {
        width: 80px;
        left: 39px;
      }
      &.sub-nav-1::after {
        width: 80px;
        left: 142px;
      }
      &.sub-nav-2::after {
        width: 80px;
        left: calc(142px + 106px);
      }
      &.sub-nav-3::after {
        width: 80px;
        left: calc(142px + 205px);
      }
      &.sub-nav-4::after {
        width: 80px;
        left: calc(142px + 304px);
      }
      &.sub-nav-5::after {
        width: 80px;
        left: calc(142px + 417px);
      }
      &.sub-nav-6::after {
        width: 80px;
        left: calc(142px + 530px);
      }
      &.sub-nav-7::after {
        width: 80px;
        left: calc(142px + 636px);
      }
      div {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        margin-right: 50px;
        cursor: pointer;
        &.cur,
        &:hover {
          color: #008afc;
          font-weight: 600;
        }
        img {
          width: 20px;
          vertical-align: sub;
          margin-right: 7px;
        }
        span {
        }
      }
    }
  }
}
</style>