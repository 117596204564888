import request from '@/utils/request'
const url = '/script/api'
// 获取接口列表
export function selectApiList(params) {
  return request({
    url: `${url}/selectApiList`,
    method: 'get',
    params: params
  })
}
// 获取接口详情
export function selectApiDetail(params) {
  return request({
    url: `${url}/selectApiDetail`,
    method: 'get',
    params: params
  })
}
// 删除接口
export function deleteApi(data) {
  return request({
    url: `${url}/deleteApi`,
    method: 'post',
    params: {},
    data
  })
}
// 创建规则
export function createRule(data) {
  return request({
    url: `${url}/createRule`,
    method: 'post',
    params: {},
    data
  })
}
// 获取规则列表
export function selectRuleList(params) {
  return request({
    url: `${url}/selectRuleList`,
    method: 'get',
    params
  })
}
// 创建接口
export function createApi(data) {
  return request({
    url: `${url}/createApi`,
    method: 'post',
    params: {},
    data
  })
}
// 更新接口
export function updateApi(data) {
  return request({
    url: `${url}/updateApi`,
    method: 'post',
    params: {},
    data
  })
}
// 修改接口脚本信息
export function updateApiScript(data) {
  return request({
    url: `${url}/updateApiScript`,
    method: 'post',
    params: {},
    data
  })
}
//调整接口开启状态
export function updateApiStatus(data) {
  return request({
    url: `${url}/updateApiStatus`,
    method: 'post',
    params: {},
    data
  })
}
//删除规则
export function deleteRule(data) {
  return request({
    url: `${url}/deleteRule`,
    method: 'post',
    params: {},
    data
  })
}
//修改规则状态
export function updateRuleStatus(data) {
  return request({
    url: `${url}/updateRuleStatus`,
    method: 'post',
    params: {},
    data
  })
}
//更新规则
export function updateRule(data) {
  return request({
    url: `${url}/updateRule`,
    method: 'post',
    params: {},
    data
  })
}
//获取接口路径一览
export function selectApiPathList() {
  return request({
    url: `${url}/selectApiPathList`,
    method: 'get'
  })
}
//执行指定接口
export function getExec(params) {
  return request({
    url: `${url}/exec`,
    method: 'get',
    params,
    data: {}
  })
}
export function postExec(data) {
  return request({
    url: `${url}/exec`,
    method: 'post',
    data,
    params: {}
  })
}
