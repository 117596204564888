<template>
  <div id="app">
    <div style="width:100%;height:99px;"></div>
    <router-view @subPageTap="backToIndex" />
    <Layout :topOrBottom="'bottom'" ref="layout"></Layout>
  </div>
</template>

<script>
import Layout from '@/view/region/common.vue'
export default {
  name: 'App',
  components: {
    Layout
  },
  methods: {
    backToIndex(item, index) {
      this.$refs.layout.pageTap(item, index)
    }
  }
}
</script>

<style lang="less">
</style>
